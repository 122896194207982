import React from "react";
import BackgroundImage from 'gatsby-background-image';
import { Container, Row, Col } from "react-bootstrap"

const Hero = (props) => {
  if (props.Img) {
    return (
      <BackgroundImage
        Tag="section"
        className={"hero"}
        fluid={props.Img}
        backgroundColor={props.BgColor}
      >
        <Container fluid>
          <Col md={{ span: 4 }}>
            <div className="hero__inner">
              <h1 className="hero__title">{props.Title}</h1>
              {props.Subtitle &&
                <p className={"hero__subtitle"}>{props.Subtitle}</p>
              }
              <p className="hero__text">{props.Text}</p>
            </div>
          </Col>
        </Container>
      </BackgroundImage>
    )
  }
  else {
    const backgroundStyle = {
      backgroundColor: props.BgColor,
      className: "hero",
      backgroundRepeat: 'repeat-y',
    };
    return (
      <Container fluid style={backgroundStyle}>
        <Row noGutters>
          <Col md={{ span: 4 }}>
            <div className="hero__inner">
              <h1 className="hero__title">{props.Title}</h1>
              {props.Subtitle &&
                <p className={"hero__subtitle"}>{props.Subtitle}</p>
              }
              <p className="hero__text">{props.Text}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
};

// export default Hero;
export default Hero
