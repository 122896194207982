import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "red-255110_1920.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const bgImage = data.desktop.childImageSharp.fluid;
  return (
    <Layout>
      <Hero
        BgColor={"#ff333a"}
        Text={"My business is founded on my faith with my wife as my business partner. With very little overhead costs we are able to offer lower prices and keep services affordable."}
        Title={"Welcome to Rugged Cross"}
        Subtitle={"Luxury Auto Detail"}
        Img={bgImage}
      />
      <div className={"about extra-space"}>
        <Container>
          <Row>
            <Col md={4}>
              <h2>Our Philosophy</h2>
              <p>
                Driven from my pure passion and love of luxury automobiles, my career in auto detailing began in 1989. Since that time I have worked for diverse market of auto detailers giving me experience in detailing a variety of vehicles, from the most challenging to luxury automobiles, such as Porsche and BMW. In the last couple years I have transformed my passion and over 20 years of experience into a full-time profession.
              </p>
            </Col>
            <Col md={4}>
              <h2>Our Mission</h2>
              <p>I find pride in providing a level of service and professionalism rarely found in today’s society. I believe in being up-front, honest, and delivering my customers high quality services that surpass their expectations.</p>
              <p>I invite you to come experience my techniques, mastered and customized to meet your individual needs.</p>
            </Col>
            <Col md={4}>
              <h2>Our Services</h2>
              <p>
                <ul>
                  <li>Full Detail</li>
                  <li>Full Interior Detail</li>
                  <li>Hand Wash/Wax</li>
                  <li>Interior Deodorizing</li>
                  <li>Paint Sealer</li>
                  <li>Full Exterior Detail</li>
                  <li>And much more...</li>
                </ul>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"text-center"}>
                <p>*Charges may differ based on size of vehicle and/or other consideration, such as large spills, stains, etc.</p>
              </div>
            </Col>
          </Row>
          <Row className={'extra-space'}>
            <h2>About Rugged Cross</h2>
            <p>
              Welcome to Rugged Cross Auto Detail where we offer several car washing and detailing services for compact, mid-sized and luxury cars, trucks and more. Several auto detail packages are available but we also offer many as stand-alone services making it easier for us to customize services to meet your needs.
            </p>
            <p>
              We believe your car is one of your biggest investments but the effects of weather and pollution, road grime and everyday wear and tear can quickly take their toll and diminish the value of your car or truck. Regular washing and detailing by Rugged Cross Auto Detail will help extend the life or your vehicle and make driving more enjoyable.
            </p>
            <p>
              Cleaning your vehicle, inside and out, is only the beginning. We use only the highest quality automotive products to not only clean but preserve your vehicle’s paint, glass, rubber, plastic, cloth and leather surfaces. If your car or truck is relatively new you may not think you need our services, but now is the best time to begin protecting your vehicles exterior paint and interior surfaces to help guard against deterioration.
            </p>
            <p>
              Preservation of a new or used vehicle is especially important if you plan to keep you car for a long time because maintaining the appearance of their vehicle also helps retain the value.
            </p>
          </Row>
        </Container>
      </div>

    </Layout>
  )
}
